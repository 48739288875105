import { AuthProvider, GraphQLProvider } from '@worx.squad/hbp-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import ProfileContext from './app/context/ProfileContext';
import { HASURA_BASE_URL } from './configs';
import { auth } from './helpers/hbp-helper';
import './styles.scss';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider auth={auth}>
      <GraphQLProvider auth={auth} url={HASURA_BASE_URL}>
        <ProfileContext>
          <Router>
            <App />
          </Router>
        </ProfileContext>
      </GraphQLProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
