import {
  IButton,
  IRefObject,
  ITextField,
  Link as LinkComponent,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import {
  Form,
  PrimaryButtonWithLoader,
  STextFieldStyle,
  TextField,
  useLoginBackendMutation,
} from '@worx.squad/shared-frontend';
import { createRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../../helpers/hbp-helper';
import { useProfileContext } from '../../context/ProfileContext';
import { loginSchema } from './login.schema';

interface LoginForm {
  email: string;
  password: string;
}

export const LoginForm = () => {
  const navigate = useNavigate();

  const profile = useProfileContext();

  const loginForm = useForm({
    mode: 'all',
    resolver: loginSchema,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit } = loginForm;

  const [{ fetching }, login] = useLoginBackendMutation();

  const onSubmit = async ({ email, password }: LoginForm) => {
    try {
      await auth.logout();

      const loginOperation = await login({
        object: {
          email,
          password,
          login_type: 'SUPER_ADMIN',
          metadata: {},
        },
      });

      if (loginOperation.error) {
        console.error(loginOperation.error.message);

        throw loginOperation.error;
      }

      await auth.refreshToken(loginOperation.data?.login?.refresh_token);

      profile?.setUserId(loginOperation.data?.login?.user_id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (profile?.userId) {
      navigate('/');
    }
    return () => profile?.setUserId(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.userId]);

  const passwordFieldRef = createRef<ITextField>();
  const loginButtonRef = createRef<IButton>();

  return (
    <Form formHook={loginForm} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        verticalAlign="center"
        verticalFill
        tokens={{ childrenGap: 20 }}
        className="w-96"
      >
        <StackItem>
          <Text variant="xxLarge">Super Admin Login</Text>
        </StackItem>
        <StackItem grow>
          <TextField
            name="email"
            formHook={loginForm}
            styles={STextFieldStyle}
            label="Email ID"
            onKeyDown={async (e) => {
              if (e.code === 'Enter') {
                passwordFieldRef?.current?.focus();
              }
            }}
          />
        </StackItem>
        <StackItem grow>
          <TextField
            componentRef={passwordFieldRef}
            type="password"
            name="password"
            formHook={loginForm}
            styles={STextFieldStyle}
            label="Password"
            revealPasswordAriaLabel="Reveal Password"
            canRevealPassword
            onKeyDown={async (e) => {
              if (e.code === 'Enter') {
                loginButtonRef?.current?.focus();
              }
            }}
          />
        </StackItem>
        <StackItem>
          <Stack horizontal horizontalAlign="end">
            <StackItem>
              <Link to="/public/forgot-password">
                <LinkComponent>Forgot password?</LinkComponent>
              </Link>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem grow className="pt-5">
          <Stack horizontal horizontalAlign="end">
            <Stack horizontalAlign="center" verticalAlign="center">
              <PrimaryButtonWithLoader
                loading={fetching}
                text="Login"
                onClick={handleSubmit(onSubmit)}
                componentRef={loginButtonRef as IRefObject<IButton>}
              />
            </Stack>
          </Stack>
        </StackItem>
      </Stack>
    </Form>
  );
};
