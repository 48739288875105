import { Text } from '@fluentui/react';

const Logo = () => {
  return (
    <div className="flex items-center space-x-3">
      <svg
        width="40"
        height="40"
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71142 24.5022C9.71142 23.5418 9.42663 22.603 8.89308 21.8045C8.35953 21.006 7.60117 20.3836 6.71391 20.0161C5.82664 19.6486 4.85033 19.5524 3.90841 19.7398C2.9665 19.9271 2.10129 20.3896 1.42221 21.0687C0.743123 21.7478 0.280662 22.613 0.0933035 23.5549C-0.094055 24.4968 0.00210317 25.4731 0.36962 26.3604C0.737137 27.2477 1.35951 28.006 2.15803 28.5396C2.95654 29.0731 3.89534 29.3579 4.85571 29.3579C6.14297 29.3561 7.37697 28.8439 8.2872 27.9337C9.19743 27.0235 9.70959 25.7894 9.71142 24.5022V24.5022Z"
          fill="white"
        />
        <path
          d="M13.4352 41.3064L23.9961 35.6679L34.557 41.3064L40.7971 35.0618L35.1585 24.4871L40.7971 13.9262L34.557 7.69531L23.9823 13.3339L13.4352 7.69531L7.19513 13.94L12.8337 24.5009L7.19513 35.0618L13.4352 41.3064ZM24.0099 27.9906L16.3372 32.169C16.9823 30.9912 18.1739 28.8033 20.4008 24.7167L20.5179 24.5032L16.3441 16.8374C17.5264 17.4848 19.7121 18.674 23.7757 20.8895L23.9892 21.0066L31.6688 16.8236L27.6006 24.2713L27.4858 24.4848L31.6734 32.1759L24.2257 28.1099L24.0099 27.9906Z"
          fill="white"
        />
        <path
          d="M28.8449 5.3557C28.8449 4.39504 28.5599 3.45597 28.0261 2.65731C27.4922 1.85864 26.7335 1.23628 25.8458 0.868962C24.9581 0.501648 23.9815 0.405891 23.0393 0.593808C22.0972 0.781725 21.2321 1.24487 20.5533 1.92464C19.8744 2.60442 19.4125 3.47026 19.2259 4.41263C19.0394 5.355 19.1365 6.33154 19.5051 7.21868C19.8737 8.10582 20.4971 8.8637 21.2965 9.39643C22.0959 9.92915 23.0354 10.2128 23.9961 10.2114C25.2821 10.2078 26.5144 9.6948 27.4231 8.78477C28.3319 7.87474 28.8431 6.64177 28.8449 5.3557Z"
          fill="white"
        />
        <path
          d="M47.9922 24.5022C47.9922 23.5418 47.7074 22.603 47.1738 21.8045C46.6403 21.006 45.8819 20.3836 44.9947 20.0161C44.1074 19.6486 43.1311 19.5524 42.1892 19.7398C41.2473 19.9271 40.382 20.3896 39.703 21.0687C39.0239 21.7478 38.5614 22.613 38.3741 23.5549C38.1867 24.4968 38.2829 25.4731 38.6504 26.3604C39.0179 27.2477 39.6403 28.006 40.4388 28.5396C41.2373 29.0731 42.1761 29.3579 43.1365 29.3579C44.4237 29.3561 45.6577 28.8439 46.568 27.9337C47.4782 27.0235 47.9904 25.7894 47.9922 24.5022V24.5022Z"
          fill="white"
        />
        <path
          d="M28.8449 43.6448C28.8449 42.6841 28.5599 41.745 28.0261 40.9464C27.4922 40.1477 26.7335 39.5253 25.8458 39.158C24.9581 38.7907 23.9815 38.695 23.0393 38.8829C22.0972 39.0708 21.2321 39.5339 20.5533 40.2137C19.8744 40.8935 19.4125 41.7593 19.2259 42.7017C19.0394 43.6441 19.1365 44.6206 19.5051 45.5077C19.8737 46.3949 20.4971 47.1528 21.2965 47.6855C22.0959 48.2182 23.0354 48.5018 23.9961 48.5005C25.2821 48.4968 26.5144 47.9839 27.4231 47.0738C28.3319 46.1638 28.8431 44.9308 28.8449 43.6448Z"
          fill="white"
        />
      </svg>
      <Text variant="xLarge" className="text-white font-extralight">
        WORX.<span className="font-bold">SQUAD</span>
      </Text>
    </div>
  );
};

export default Logo;
