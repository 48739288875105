import { useAuth } from '@worx.squad/hbp-react';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute: FC = ({ children }) => {
  const { signedIn } = useAuth();
  const location = useLocation();

  if (!signedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }
  return <>{children}</>;
};

export default ProtectedRoute;
