import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SuperAdminTableDataSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SuperAdminTableDataSubscription = {
  __typename?: 'subscription_root';
  organization: Array<{
    __typename?: 'organization';
    organization_name: string;
    created_at: any;
    address?: { __typename?: 'address'; address_line: string } | null;
    organization_employees_aggregate: {
      __typename?: 'organization_employee_aggregate';
      aggregate?: {
        __typename?: 'organization_employee_aggregate_fields';
        count: number;
      } | null;
    };
    organization_admins: Array<{
      __typename?: 'organization_admin';
      user?: {
        __typename?: 'users';
        id: any;
        display_name?: string | null;
        account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
        profile: Array<{ __typename?: 'profile'; id: any }>;
      } | null;
    }>;
  }>;
};

export function useSuperAdminTableDataSubscription<
  TData = Operations.SuperAdminTableDataSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.SuperAdminTableDataSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.SuperAdminTableDataSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.SuperAdminTableDataSubscription,
    TData,
    Operations.SuperAdminTableDataSubscriptionVariables
  >({ query: Operations.SuperAdminTableDataDocument, ...options }, handler);
}
