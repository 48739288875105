import { AuthLayout, LoginVector } from '@worx.squad/shared-frontend';
import { LoginForm } from './LoginForm';

const Login = () => {
  return (
    <AuthLayout vector={<LoginVector />}>
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
