import { TestImages } from '@fluentui/example-data';
import {
  DefaultButton,
  IStackTokens,
  Persona,
  PersonaSize,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { auth } from '../../../helpers/hbp-helper';
import { useProfileContext } from '../../context/ProfileContext';
import Logo from './Logo';
import Subscriptions from './Subscriptions';
import Table from './Table';
import { useSuperAdminTableDataSubscription } from './dashboard.generated';

const Dashboard = () => {
  const [{ data, fetching }] = useSuperAdminTableDataSubscription({});
  const profile = useProfileContext();
  const stackTokens: IStackTokens = { childrenGap: 0 };

  if (fetching) return <div>Loading...</div>;

  return (
    <div className="bg-background h-screen flex flex-col space-y-4">
      <div className="w-full py-3 px-2 bg-primary flex items-center justify-between">
        <div className="flex space-x-10 pl-16">
          <Logo />
          <Subscriptions value={data?.organization.length || 0} />
        </div>
        <div className="flex items-center ">
          <Persona
            imageUrl={TestImages.personaFemale}
            text={profile?.name}
            size={PersonaSize.size40}
            hidePersonaDetails={false}
          />
          <DefaultButton
            text="Log Out"
            className="mr-2"
            onClick={async () => {
              await auth.logout();
            }}
          />
        </div>
      </div>
      <div className="flex flex-col items-center py-4 ">
        <Stack horizontal tokens={stackTokens}>
          <PrimaryButton
            text="Existing Accounts"
            className="w-48 rounded-r-none"
          />
          <DefaultButton
            text="Requests"
            className="bg-transparent w-48 rounded-l-none"
          />
        </Stack>
        <Table data={data} />
      </div>
    </div>
  );
};

export default Dashboard;
