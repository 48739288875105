import { initializeIcons } from '@fluentui/react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard, Login, ProtectedRoute } from './components';

initializeIcons(undefined, { disableWarnings: true });

const App = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
