import { Text } from '@fluentui/react';

interface SubscriptionsProps {
  value: number;
}

const Subscriptions = ({ value }: SubscriptionsProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Text variant="large" className="font-light text-white">
        Subscriptions
      </Text>
      <div className="text-white w-5 text-center text-xs leading-5 rounded-full bg-status-green">
        {value}
      </div>
    </div>
  );
};

export default Subscriptions;
