import React, { createContext, useContext, useState } from 'react';

interface ProfileContextValue {
  userId: string | undefined;
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
  name: string;
}

interface ProfileContextProps {
  children: React.ReactNode;
}

const profileContext = createContext<ProfileContextValue | undefined>(
  undefined,
);

const ProfileContext = ({ children }: ProfileContextProps) => {
  const [userId, setUserId] = useState<string>();
  const [name, setName] = useState<string>('');

  return (
    <profileContext.Provider value={{ userId, setUserId, name }}>
      {children}
    </profileContext.Provider>
  );
};

export const useProfileContext = () => useContext(profileContext);
export default ProfileContext;
