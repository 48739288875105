import {
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IRenderFunction,
  ISearchBoxStyles,
  SearchBox,
  SelectionMode,
  Text,
} from '@fluentui/react';
import cn from 'classnames';
import moment from 'moment';
import { SuperAdminTableDataSubscription } from './dashboard.generated';

type DataItem = {
  key: number;
  company_name: string;
  company_location: string;
  subscription_status: string;
  owner_email: string;
  owner_name: string;
  no_of_users: number;
  subscription_start_date: string;
};

type ColumnField = keyof (Omit<
  DataItem,
  'company_name' | 'company_location' | 'subscription_status'
> & { company_name_location: string });

export interface TableProps {
  data: SuperAdminTableDataSubscription | undefined;
}

const Table = ({ data }: TableProps) => {
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Company Name Location',
      fieldName: 'company_name_location',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },

    {
      key: 'column2',
      name: 'Owner Name',
      fieldName: 'owner_name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: 'Owner Email',
      fieldName: 'owner_email',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },

    {
      key: 'column4',
      name: 'No. of Users',
      fieldName: 'no_of_users',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },

    {
      key: 'column5',
      name: 'Subscription Start Date',
      fieldName: 'subscription_start_date',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    DetailsRow,
  ) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props && DetailsRow) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = {
          backgroundColor: '#f0f8fa',
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  // https://stackoverflow.com/questions/64716971/is-there-a-way-to-make-a-detailslist-header-text-wrap-in-fluent-ui
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    headerProps,
    defaultRender,
  ) => {
    if (!headerProps || !defaultRender) {
      return null;
    }
    return defaultRender({
      ...headerProps,
      className: 'border-t-2 border-b-2 mb-4 pb-4 border-table-header',
      styles: {
        root: {
          backgroundColor: '#f6f6f6',
          '.ms-DetailsHeader-cell': {
            whiteSpace: 'normal',
            textOverflow: 'clip',
            lineHeight: 'normal',
          },
          '.ms-DetailsHeader-cellTitle': {
            height: '100%',
            alignItems: 'center',
            color: '#323130',
          },
        },
      },
    });
  };

  const onRenderItemColumn = (
    item: DataItem,
    index?: number | undefined,
    column?: IColumn | undefined,
  ): React.ReactNode => {
    if (!column?.fieldName) return null;

    const field = column.fieldName as ColumnField;

    if (field === 'company_name_location')
      return (
        <div className="flex space-x-2 items-center">
          <div
            className={cn('h-2 w-2 rounded-full', {
              'bg-status-green': item.subscription_status === 'active',
              'bg-status-yellow':
                item.subscription_status === 'about_to_expire',
              'bg-status-red': item.subscription_status === 'expired',
            })}
          ></div>
          <div className="flex flex-col">
            <Text variant="medium" className="font-semibold leading-tight">
              {item.company_name}
            </Text>
            <Text variant="smallPlus" className="leading-tight">
              {item.company_location}
            </Text>
          </div>
        </div>
      );

    return item[field];
  };

  const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 250 } };
  return (
    <div className="flex flex-col w-11/12">
      <div className="flex justify-between my-4">
        <div className="flex flex-col space-y-3">
          <h3 className="text-sm font-light">Subscription Status</h3>
          <div className="flex space-x-4 text-xs font-light">
            <div className="flex space-x-2 items-center">
              <div className="h-2 w-2 bg-status-green rounded-full"></div>
              <h5>Active</h5>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="h-2 w-2 bg-status-yellow rounded-full"></div>
              <h5>About to Expire</h5>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="h-2 w-2 bg-status-red rounded-full"></div>
              <h5>Expired</h5>
            </div>
          </div>
        </div>
        <SearchBox value="" styles={searchBoxStyles} placeholder="Search" />
      </div>
      <DetailsList
        {...{ columns, onRenderRow, onRenderDetailsHeader, onRenderItemColumn }}
        selectionMode={SelectionMode.none}
        items={
          data?.organization?.map((organization, index) => {
            return {
              key: `row-${index}`,
              company_name: organization.organization_name,
              company_location: organization.address?.address_line,
              subscription_status: 'active',
              owner_email:
                organization.organization_admins[0].user?.account?.email,
              owner_name:
                organization.organization_admins[0].user?.display_name,
              no_of_users:
                organization.organization_employees_aggregate.aggregate
                  ?.count || 0,
              subscription_start_date: organization.created_at
                ? moment(organization.created_at).format('DD-MM-YYYY')
                : null,
            };
          }) || []
        }
      />
    </div>
  );
};

export default Table;
